.inner-main-content {
    display: flex;
    /* flex-direction: column; */
    gap: 9px;
    width: 100%;
    flex-wrap: wrap;
}

.my-video {
    display: flex;
    flex-wrap: nowrap;
    gap: 9px;
    width: 100%;
}

.my-video video {
    flex: 1;
    width: 30%; /* limit the maximum width of each video to 30% of the container width */
    height: auto;
    display: block;
    border-radius: 20px;
}


/* Media query for mobile view */
@media (max-width: 700px) {
    .my-video {
        width: 100%;
        margin-top: 9px;
        gap: 9px;
        flex-direction: column;
    }

    .my-video video {
        width: 100%; /* allow each video to take up the full width in mobile view */
    }
}
