.profile-details {
  padding: 16px;
  background: #E9E9E9;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.profile-details:hover {
  background-color: #E0E0E0;
}


.profile-image img {
  width: 53px;
  height: 53px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0;
}

.profile-name {
  font-family: 'MatterSQTRIAL', sans-serif;
  font-style: normal;
  font-weight: 570;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-top: 9px;
  margin-bottom: 5px;
}

/*.profile-title {
 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 1px;

  
  background: linear-gradient(90deg, #3F77E2 0%, #0127D2 8.96%, #5723B9 17.92%, #9D30D0 27.75%, #D438DA 41.06%, #B42F6D 51.26%, #C22D36 61.34%, #C72E2A 71.16%, #CD6E4B 80.12%, #E0A43E 87.34%, #E7CB55 92.56%, #E2E498 97.36%, #B6F338 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

} */

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.profile-title {
  /* Design Generalist* */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-top: 0;
  margin-bottom: 1px;

  /* linear gradient */
  background: linear-gradient(90deg, #3F77E2 0%, #0127D2 8.96%, #5723B9 17.92%, #9D30D0 27.75%, #D438DA 41.06%, #B42F6D 51.26%, #C22D36 61.34%, #C72E2A 71.16%, #CD6E4B 80.12%, #E0A43E 87.34%, #E7CB55 92.56%, #E2E498 97.36%, #B6F338 100%);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  /* animation */
  animation: gradient-animation 5s linear infinite;
}

.profile-metatitle {
  /* (*aspiring motorcycle mechanic) */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #646464;
  margin: 0;
  margin-bottom: 10px;
}

.profile-description {
  /* A design generalist that is passionate for design systems that progress the advancement of humans and the planet. */
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #3F3F3F;
  margin: 0px;
  max-width: 340px;
}

.project-card-arrow {
  position: absolute;
  top: 15px;
  right: 16px;
}

.project-card-arrow svg path {
  transition: stroke 0.3s ease; /* Add a transition effect for the stroke color */
}

.project-card-arrow:hover svg path {
  stroke: #121AFD; /* Change the stroke color on hover */
}