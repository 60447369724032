.tags-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.tag-item {
    background-color: white;
    border-radius: 100px;
    padding: 6px 13px;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
    color: #3F3F3F;
}