.inner-content-about {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 9px;
    width: 100%;
}

.about div {
    flex: 1;
}

.flowerz-image {
    display: flex;
    width: 100%;
    max-width: 700px;
    border-radius: 20px;
    gap: 9px;
}
.mind-map {
    display: flex;
    width: 100%;
    max-width: 700px;
    gap: 9px;
}

.update-section {
    display: flex;
    width: 100%;
    max-width: 568px;
   color: red;
}


.update-section div {
    flex: 1;
}


/* Media query for viewports */
@media (max-width: 700px) {
    .update-section {
        flex-direction: column;
    }
}

