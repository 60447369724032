.coder-image {
    display: flex;
    width: 100%;
    border-radius: 20px;
    gap: 9px;
}

/* Media query for viewports */
@media (max-width: 700px) {
    .update-section {
        flex-direction: column;
    }

    .dreamhouse-inner-content {
        margin-top: 9px;
        gap: 9px;
    }
}
