.project-navbar {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
}

.navbar-btn {
  /* padding: 11px 16px; dont know why this wont center properly cb03292023 */
  font-family: 'Inter Tight', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 93.9%;
  color: #fff;
  background: #bebebe;
  border-radius: 100px;
  padding: 11px 16px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s;
}

.navbar-btn2 {
  /* padding: 11px 16px; dont know why this wont center properly cb03292023 */
  font-family: 'Inter Tight', sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 93.9%;
  color: #fff;
  background: #121afd;
  border-radius: 100px;
  padding: 11px 16px;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s;
}

.navbar-btn:hover {
  background-color: #E0E0E0;
}

.navbar-btn2:hover {
  background-color: #9CEB36;
}

.cool-logo {
  display: flex;
  width: auto;
  height: 34px;
  gap: 9px;
}