.right-sidebar-container {
    display: flex;
    flex-direction: column;
    gap: 9px;
    position: sticky;
    top: 0;
}

.sidebar-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background: #E9E9E9;
    border-radius: 15px;
    box-sizing: border-box;
}

.sidebar-item-label {
    font-family: 'MatterSQTRIAL', sans-serif;
    font-style: normal;
    font-weight: 570;
    font-size: 16px;
    line-height: 93.9%;
}

.sidebar-item-subtitle {
    font-family: 'Inter';
    font-style: normal;
    color: #3f3f3f;
    font-size: 13px;
    line-height: 93.9%;
    margin-top: 4px;
}

.arrow-out svg path {
    transition: stroke 0.3s ease; /* Add a transition effect for the stroke color */
  }
  
  .arrow-out:hover svg path {
    stroke: #121AFD; /* Change the stroke color on hover */
  }

  .sidebar-item:hover {
    background-color: #E0E0E0;
  }