/* SelectedProjects.css */
  .project-card {
    background: #E9E9E9;
    border-radius: 20px;
    position: relative;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 9px;
  }

  .project-card:hover{
    background-color: #E0E0E0;
  }
  
  .project-list {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }

  .project-card-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .project-card-content {
    display: flex;
    gap: 14px;
  }
  
  .project-card-title {
    font-family: 'MatterSQTRIAL', sans-serif;
    font-style: normal;
    font-weight: 570;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 1px;
  }
  
  .project-card-description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #3F3F3F;
    margin-top: 0;
    max-width: 260px;
  }
  
  .project-card-tag {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 14px;
    gap: 10px;
    background: #BEBEBE;
    border-radius: 51px;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 93.9%;
    color: #ffffff;
  }
  
  .project-card-image {
    width: 62px;
    height: 62px;
    border-radius: 10px;
  }

  .project-selection-arrow svg path {
    transition: stroke 0.3s ease; /* Add a transition effect for the stroke color */
  }

  .project-selection-arrow:hover svg path {
    stroke: #121AFD; /* Change the stroke color on hover */
  }