.dreamhouse-inner-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 9px;
    width: 100%;
}

.dreamhouse-about div {
    flex: 1;
}

.dreamhouse-image {
    display: flex;
    width: 100%;
    /* max-width: 700px; */
    border-radius: 20px;
    gap: 9px;
}

.additional-logos2 {
    display: flex;
    gap: 9px;
}

.additional-logo-item2 div {
    flex: 1;
}

.additional-logos2 img {
    width: 100%;
    /* max-width: 428px; */
    height: auto;
    display: block;
    border-radius: 20px;
}

/* Media query for viewports */
@media (max-width: 700px) {
    .update-section {
        flex-direction: column;
    }

    .dreamhouse-inner-content {
        margin-top: 9px;
        gap: 9px;
    }

    .additional-logos2 {
        flex-direction: column;
    }

    .dreamhouse-image {
        border-radius: 20px;
        
    }
}

