.about-page {
    display: flex;
    gap: 9px;
    width: 100%;
}

.left-main {
    width: calc(100% - 380px);
}

.right-sidebar {
    width: 380px;
}

.info-section {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
    margin-top: 6px;
}

.automatic-slideshow {
    display: flex;
    width: 100%;
    max-width: 1326px;
    max-height: 500px;
    object-fit: cover;
    border-radius: 20px;
    gap: 9px;
}

.info-section-inner {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 9px;
}

/* Media query for viewports */

@media (max-width: 1200px) {
    .about-page {
        flex-direction: column;
    }

    .left-main {
        width: 100%;
    }

    .right-sidebar {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .info-section {
        flex-direction: column;
    }
    .automatic-slideshow {
        max-height: 250px;
        object-fit: cover;
    }
}

/* Slick Slider */

.slick-slider {
    width: 100%;
}

.slideshow {
    position: relative;
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
}

.slick-slide div {
    outline: none;
}

.slick-slide > div {
    margin: 0 5px;
}

.slick-list {
    margin: 0 -5px;
}

.slick-dots {
    bottom: 16px;
}

.slick-dots li {
    margin: 0;
}

.slick-dots li button:before {
    opacity: 0.5;
    color: white;
}

.slick-dots li.slick-active button:before {
    color: white;
}

.slick-dots li button {
    padding: 3px;
}


