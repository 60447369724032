.card-container {
    background: #E9E9E9;
    border-radius: 20px;
    padding: 15px;
    gap: 10px;
    width: 100%;
    min-width: 280px;
    box-sizing: border-box;
}

.card-title {
    font-family: 'MatterSQTRIAL', sans-serif;
    font-style: normal;
    font-weight: 570;
    font-size: 16px;
    line-height: 93.9%;
    margin-bottom: 10px;
}

.card-subtitle {
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    /* font-weight: 300; */
    font-size: 12px;
    line-height: 93.9%;
    margin-bottom: 10px;
    color: #646464;
}

.card-content {
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
    line-height: 140%;
    color: #3f3f3f;
}