.inner-content {
    display: flex;
    flex-direction: column;
    gap: 9px;
    width: 100%;
}

.additional-main-logo {
    width: 100%;
    background: #005048;
    border-radius: 20px;
    padding: 120px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.additional-main-logo img {
    width: 50%;
    display: block;
}

.additional-logos {
    display: flex;
    gap: 9px;
}

.additional-logo-item div {
    flex: 1;
}

.additional-logos img {
    width: 100%;
    max-width: 428px;
    height: auto;
    display: block;
    border-radius: 20px;
}

.subscribe-section {
    width: 100%;
    background: #005048;
    border-radius: 20px;
    /* padding: 74px 16px; */
    box-sizing: border-box;
    display: flex;
     align-items: center; 
    justify-content: center;
}

.subscribe-inner-section img {
    width: 100%;
    /* max-width: 807px; */
    display: block;
    border-radius: 20px;
}

 .subscribe-inner-section { 
    width: 100%;
    color: #ffffff;
    font-size: 22px;
    border-radius: 20px;
    padding: 30px;
    box-sizing: border-box;
} 

.information-section {
    display: flex;
}

.deeper-information {
    background: #E9E9E9;
    border-radius: 20px;
    padding: 15px;
}

.information-section div {
    flex: 1;
}

.deeper-information-title {
    font-family: 'MatterSQTRIAL', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    font-size: 16px;
    line-height: 93.9%;
}

.deeper-information-description {
    margin: 10px 0;
    color: #3F3F3F;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
}

.deeper-information-credits {
    color: #3f3f3f;
    font-family: 'Inter Tight', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 115.4%;
}


/* Media query for viewports */
@media (max-width: 700px) {
    .inner-content {
        margin-top: 9px;
        gap: 9px;
    }

    .additional-logos {
        flex-direction: column;
    }

    .subscribe-section {
        padding: 16px;
    }

    .subscribe-inner-section {
        width: 100%;
        font-size: 14px;
        padding: 1px;
    }

    .additional-main-logo {
        border-radius: 20px;
        padding: 64px 38px;
    }

    .additional-main-logo img {
        width: 100%;
        display: block;
    }

    .information-section {
        flex-direction: column;
    }
}


