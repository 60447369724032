.App {
  height: 100vh;
  width: 100%;
  padding-top: env(safe-area-inset-top, 20px);
  padding-bottom: env(safe-area-inset-bottom, 20px);
  background-color: white;
}

.container {
  padding-top: env(safe-area-inset-top, 20px);
  padding-bottom: env(safe-area-inset-bottom, 20px);
}

.App-inner {
  display: flex;
  padding-left: 9px;
  height: 100vh;
}


.App-inner::-webkit-scrollbar {
  position: absolute;
  margin-right: 20px;
}


.App-inner .sidebar {
  background-color: white;
  width: 100%;
  max-width: 430px;
  height: 100vh;
  overflow-y: scroll;
  position: -webkit-sticky;
  position: sticky;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding-top: 9px;
  padding-right: 9px;
  padding-bottom: 9px;
}


.App-inner .content {
  flex: 1;
  max-width: calc(100vw - 390px);
  overflow: auto;
  padding-right: 9px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.App-inner .sidebar::-webkit-scrollbar {
  display: none;
}

.App-inner .content::-webkit-scrollbar {
  display: none;
}

.nav {
  display: block;
}

/* Media query for viewports */
@media (max-width: 700px) {
  .App-inner {
    flex-direction: column;
    height: auto;
    gap: 9px;
  }

  .App-inner .sidebar {
    height: auto;
  }

  .App-inner .content {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .App-inner > .content {
    max-width: calc(100% - 9px);
    margin-top: -9px;
    padding-bottom: 9px;
  }
}


@media only screen 
  and (device-width: 428px) 
  and (device-height: 926px) 
  and (-webkit-device-pixel-ratio: 3) {

  /* Add your custom styles here */
}
